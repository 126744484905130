import React, { useState } from 'react';
import styles from './ProfileImg.module.css';

const imgSrc = [
    "img/me2.png",
    "img/me_doodle.png",
    "img/me.png",
    "img/me_dith.png",
];

const ProfileImg = () => {
    const [imgId, setImgId] = useState(0);

    const changeImg = () => setImgId((imgId + 1) % imgSrc.length);

    return (
      <div className={styles.container}>
        <img className={styles.profileImg} onClick={() => changeImg()} src={imgSrc[imgId]} alt="It's me!" />
        <p className={styles.clickMe}>Click me!</p>
      </div>
    );
};

export default ProfileImg;