import React from 'react';
import styles from './CV.module.css';
import ProfileImg from './ProfileImg';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faUser, faBriefcase, faHatWizard, faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faSoundcloud, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Score from './Score';
import workentries from '../CVEntriesWork.json';
import eductionentries from '../CVEntriesEducation.json';
import WorkEntry from './WorkEntry';
import EducationEntry from './EducationEntry';


const CV = () => {
  return (
    <section id="resume">
      <div className="spacer"></div>
      <div className={cn(styles.container, "shadow card")}>
        <div className={styles.profile}>
          <span className={styles.hat}>
            <FontAwesomeIcon icon={faHatWizard} />
          </span>
          <ProfileImg />
          <div className={styles.contact}>
            <ul>
              <li>
                <FontAwesomeIcon icon={faUser} />
                <p>David Henriksson</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} />
                <p>kdhenriksson [at] gmail [dot] com</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} />
                <p>+46 70 680 90 26</p>
              </li>
            </ul>
          </div>
          <hr />
          <div className={styles.aboutMe}>
            <h1>About me</h1>
            <p>
              I'm a software engineer, born and raised in Östersund, Sweden, currently residing in Stockholm, Sweden. I have been working with web development since 2016, most recently as a freelancing full stack developer, but I'm currently looking for a career in game development.
            </p>
            <p>My education is within computer science, and I have specialized in theoretical computer science topics such as algorithms, language and semantics. As of 2022 I am majoring in Visualization &amp; Interactive Graphics.</p>
            <p>I'm most proficient with low level and systems programming - I enjoy working with pointers, memory, assembly, the closer I can work to the machine, the more excited I get.</p>
            <p>Outside of work and studies I enjoy working on my own programming projects, making music, playing games, photography or consuming game lore.</p>
          </div>
          <hr />
          <div className={styles.links}>
            <h1>External links</h1>
            <ul>
              <li>
                <FontAwesomeIcon icon={faGithub} />
                <a href="https://github.com/sieken">sieken</a>
              </li>
              <li>
                <FontAwesomeIcon icon={faSoundcloud} />
                <a href="https://soundcloud.com/kouhai">kouhai</a>
              </li>
              <li>
                <FontAwesomeIcon icon={faLinkedin} />
                <a href="https://www.linkedin.com/in/david-henriksson-43a239185/">David Henriksson</a>
              </li>
            </ul>
          </div>
          <div className={styles.subliminalMessage}>
            <img src="img/hiremecat.png" alt="subliminal message" className={styles.hiremecat} />
            <p>[SUBLIMINAL MESSAGE CAT]</p>
          </div>
        </div>
        <div className={styles.resume}>
          <div className={styles.resumeList}>
            <h2>
              <FontAwesomeIcon icon={faHatWizard} />
              &nbsp;Skills
            </h2>
              <div className={styles.columns}>
              <section>
                <h3>Programming &amp; Computer skills</h3>
                <table>
                  <thead>
                    <tr><th>Language</th><th>Experience</th></tr>
                  </thead>
                  <tbody>
                    <tr><td>C</td><td><Score score={4} /></td></tr>
                    <tr><td>C++</td><td><Score score={4} /></td></tr>
                    <tr><td>GLSL & HLSL</td><td><Score score={3} /></td></tr>
                    <tr><td>Assembly (x86, MIPS, 6502, Z80)</td><td><Score score={3} /></td></tr>
                    <tr><td>JavaScript (Typescript, Node)</td><td><Score score={5} /></td></tr>
                    <tr><td>Python</td><td><Score score={2} /></td></tr>
                    <tr><td>C#</td><td><Score score={2} /></td></tr>
                    <tr><td>HTML &amp; CSS</td><td><Score score={4} /></td></tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr><th>Operating system</th><th>Experience</th></tr>
                  </thead>
                  <tbody>
                    <tr><td>Linux</td><td><Score score={5} /></td></tr>
                    <tr><td>Windows</td><td><Score score={4} /></td></tr>
                    <tr><td>MacOS</td><td><Score score={3} /></td></tr>
                  </tbody>
                </table>
              </section>
              <section>
                <h3>Workplace skills</h3>
                <table>
                  <thead>
                    <tr><th>Skill</th><th>Level</th></tr>
                  </thead>
                  <tbody>
                    <tr><td>Technical</td><td><Score score={5} /></td></tr>
                    <tr><td>Communication</td><td><Score score={3} /></td></tr>
                    <tr><td>Initiative</td><td><Score score={4} /></td></tr>
                    <tr><td>Teamwork</td><td><Score score={4} /></td></tr>
                    <tr><td>Leadership</td><td><Score score={2} /></td></tr>
                  </tbody>
                </table>
                <h3>Language skills</h3>
                <table>
                  <thead>
                    <tr><th>Skill</th><th>Level</th></tr>
                  </thead>
                  <tbody>
                    <tr><td>Swedish</td><td><Score score={5} /></td></tr>
                    <tr><td>English</td><td><Score score={5} /></td></tr>
                    <tr><td>Japanese</td><td><Score score={4} /></td></tr>
                  </tbody>
                </table>
              </section>
            </div>
          </div>
          <div className={styles.resumeList}>
            <h2>
              <FontAwesomeIcon icon={faBriefcase} />
              &nbsp;Work
            </h2>
            <div className={styles.columns}>
              <ul>
                {workentries.slice(0, Math.floor(workentries.length / 2)).map(w => <WorkEntry key={w.company} entry={w} />)}
              </ul>
              <ul>
                {workentries.slice(Math.floor(1 + workentries.length / 2)).map(w => <WorkEntry key={w.company} entry={w} />)}
              </ul>
            </div>
          </div>
          <div className={styles.resumeList}>
            <h2>
              <FontAwesomeIcon icon={faUserGraduate} />
              &nbsp;Education
            </h2>
            <ul>
              {eductionentries.map((e, i) => <EducationEntry key={e.school + i} entry={e} />)}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CV;
