import React from 'react';
import cn from 'classnames';
import styles from './ActiveItem.module.css';

const ActiveItem = ({item, expanded}) => {
    return (
      <div className={cn(styles.container, 'shadow card', expanded && styles.expanded)}>
        <div className={styles.media}>
            <img src={item.src} alt={item.title} />
        </div>
        <div className={styles.info}>
            <h1>{item.title}</h1>
            <p className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />
            <p className={styles.techniques}>{item.techniques}</p>
        </div>
      </div>
    );
};

export default ActiveItem;