// import BGShader from './components/BGShader';
import Header from './components/Header';
// import Shaders from './components/Shaders';
import Stuff from './components/Stuff';
import styles from './App.module.css';
import CV from './components/CV';

function App() {
  return (
    <>
    <div className={styles.App}>
        <div className={styles.parallax} />
        <div className={styles.content}>
          <Header />
          {/* <BGShader /> */}
          {/* <Shaders /> */}
          <Stuff />
          <CV />
        </div>
    </div>
    </>
  );
}

export default App;
