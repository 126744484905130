import React from 'react';

const WorkEntry = ({ entry }) => {
    return (
        <li>
            <h5>{entry.company}</h5>
            <p>{entry.description}</p>
            <p>{entry.dates}</p>
        </li>
    );
};

export default WorkEntry;