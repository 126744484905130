import React from 'react';

const EducationEntry = ({ entry }) => {
    return (
        <li>
            <h5 className="inst-title">{entry.school}</h5>
            <p className="location">{entry.location}</p>
            <p className="description" dangerouslySetInnerHTML={{__html: entry.description}} />
            <p className="time-period">{entry.dates}</p>
        </li>
    );
};

export default EducationEntry;