import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import styles from './Score.module.css';

const Score = ({ score }) => {
    const stars = [];
    for (let i = 0; i < score; ++i) stars.push(<FontAwesomeIcon key={`score-${i}`} icon={faStar} />)
    return (
        <div className={styles.score}>
            {stars}
        </div>
    );
};

export default Score;