import React, { useState } from 'react';
import ActiveItem from './ActiveItem';
import Item from './Item';
import stuff from '../stuff.json';
import styles from './Stuff.module.css';

const Stuff = () => {
  const [active, setActive] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const toggleActive = (index) => {
    if (index === active) setExpanded(!expanded)
    else {
      setExpanded(true);
      setActive(index);
    }
  };

  return (
  <div id="stuff" className={styles.container}>
    <ActiveItem expanded={expanded} item={stuff[active]}></ActiveItem>
    <div className={styles.list}>
      {stuff.map((s, i) => (
        <div key={s.title + i} onClick={() => toggleActive(i)}>
          <Item itemContent={{...s, title: `${(i + 1).toString().padStart(2, '0')}. ${s.title}`}} />
        </div>
      ))}
    </div>
  </div>
  );
};

export default Stuff;