import React from 'react';
import cn from 'classnames';
import styles from './Header.module.css';

const Header = () => {
  return (
    <div className={styles.container}>
      <div className={cn(styles.header, 'shadow card')}>
        <h1>WrongPortal</h1>
      </div>
      <div className={styles.wiggle}>
        <div className={cn(styles.intro, 'shadow card', styles.float)}>
          <p><b>Hi, I'm sieken!</b></p>
          <p>I am a software engineer, looking for work in the <b className={styles.purple}>game development industry</b>. This is a showcase of random <a href="#stuff">stuff</a> I've done as part of my studies and personal projects, and there's a proper <a href="#resume">resume</a> a bit further down.</p>
          <p>During my education I have focused on <b className={styles.salmon}>analysis and problem solving</b> within software engineering, and I have specialized in theoretical computer science topics such as <b className={styles.blue}>algorithms, language and semantics</b>. I have since 2022 switched my MSE major to <b className={styles.green}>Visualization &amp; Interactive Graphics</b>.</p>
          <p>When I'm not working or studying, I enjoy various creative endeavors like working on my own programming projects or making music, playing around with shaders, photography, playing games or consuming game lore.</p>
          <p>Currently I am working on writing my own game in C and C++ <b className={styles.blue}>from scratch</b>.</p>
        </div>
      </div>
    </div>
  );
};

export default Header;