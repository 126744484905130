import React from 'react';
import styles from './Item.module.css';
import cn from 'classnames';

const Item = ({ itemContent }) => {
  return <div className={cn(styles.item, 'shadow card')}>
    <img src={itemContent.src} alt={itemContent.title} />
    <div className={styles.info} style={{ color: itemContent.color || 'white' }}>
      <div className={styles.title}>
        <h1>{itemContent.title}</h1>
      </div>
      <div className={styles.extra}>
        <p className={styles.category}>{itemContent.category || 'misc'}</p>
        <p className={styles.year}>{itemContent.year}</p>
      </div>
    </div>
  </div>;
};

export default Item;